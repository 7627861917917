const LANG = $('html').attr('lang');
const cookie = require('cookie_js');

let geoEnTransParams = {
	change_case: false,
	replace_space: ' ',
	replace_other: '-'
};
// let defaultCityName;
//
// if (LANG == 'ru') {
// 	defaultCityName = 'Москва';
// } else if (LANG == 'en') {
// 	defaultCityName = 'Moscow';
// }
//
// let defaultCookie = {
// 	geoCheckIp: 0,
// 	geoCityId: 2097,
// 	geoCityName: defaultCityName,
// 	geoCityTranslit: `moscow`
// };

//cookie.setDefault({BITRIX_SM_GEO: JSON.stringify(defaultCookie)});

global.getArrayCities = function () {
	return getJson('/local/php_interface/tools/ipgeobase.ru/cities.txt', 'text/plain; charset=windows-1251')
		.then(function (data) {
			const CITIES = JSON.stringify(data);
			let arrCITIES = CITIES
								.slice(1, -1)
								// .split('\\r\\n');
								.split('\\n');
			let arrayObjCities = arrCITIES.filter(function (item) {
				let thisArray = item.split('\\t');
				if (thisArray[0].length == 0 || ~thisArray[3].toUpperCase().indexOf('УКРАИНА')) {
					return false;
				}
				return true;
			}).map(function (item, i, arr) {
				let thisArray = item.split('\\t'),
					obj = {};
				obj.id = thisArray[0];

				if (LANG == 'ru') {
					obj.text = thisArray[1];
				} else if (LANG == 'en') {
					if (thisArray[1].toUpperCase() == 'МОСКВА') {
						obj.text = 'Moscow';
					} else if (thisArray[1].toUpperCase() == 'САНКТ-ПЕТЕРБУРГ') {
						obj.text = 'Saint Petersburg';
					} else {
						obj.text = BX.translit(thisArray[1], geoEnTransParams);
					}
				}

				if (thisArray[1].toUpperCase() == 'МОСКВА') {
					obj.translit = `moscow`;
				} else if (thisArray[1].toUpperCase() == 'САНКТ-ПЕТЕРБУРГ') {
					obj.translit = 'saint_petersburg';
				} else {
					obj.translit = BX.translit(thisArray[1]);
				}

				let $radioBtn = $('.radio--geo').find(`[data-translit="${obj.translit}"]`);
				if ($radioBtn.length) {
					$radioBtn.val(obj.id);
					obj.disabled = true;
				}
				// console.log(obj.text);
				return obj;
			});

			return arrayObjCities;
		})
		.catch(function (error) {
			console.error('ошибка: ' + error);
			showNotyfications(error, {type: 'error'});
		});
};

function setGeoAside() {
	const $asideCurrentCity = $(document).find('#geoAsideCityName');
	let geoCookie = JSON.parse(cookie('BITRIX_SM_GEO'));

	if (geoCookie.geoCityName) {
		$asideCurrentCity.text(geoCookie.geoCityName);
	}
}

$(document).bind('cbox_complete', function () {
	let geoCookie = JSON.parse(cookie('BITRIX_SM_GEO'));
	// console.log(geoCookie);
	if ($('#colorbox').find('[data-id="geolocation"]').length) {
		if ($('#geoCity').find('option').length <= 1) {
			getArrayCities()
				.then(function (data) {
					// console.log(data);
					let lang = $('html').attr('lang');
					$('#geoCity')
						.select2({
							data: data,
							theme: 'filled',
							escapeMarkup: function (markup) {
								return markup;
							},
							minimumInputLength: 1,
							language: lang
						});
					return data;
				})
				.then(function (data) {
					if (geoCookie.geoCityTranslit) {
						let $radio = $(`.js-radio--geo[data-translit="${geoCookie.geoCityTranslit}"]`);
						// console.log($radio);
						if ($radio.length) {
							$radio.click();
							$('#geoCity')
								.val('')
								.trigger('change');
						} else {
							$('#geoCity')
								.val(geoCookie.geoCityId)
								.trigger('change');
						}
					}
					return data;
				});
		} else {
			if (geoCookie.geoCityTranslit) {
				let $radio = $(`.js-radio--geo[data-translit="${geoCookie.geoCityTranslit}"]`);
				if ($radio.length) {
					$radio.click();
					$('#geoCity')
						.val('')
						.trigger('change');
				} else {
					$('#geoNull').click();
					$('#geoCity')
						.val(geoCookie.geoCityId)
						.trigger('change');
				}
			}
		}

		$(document).find('.js-geo-current').text(geoCookie.geoCityName);
		$(document).find('.js-geo-current').attr('data-id', geoCookie.geoCityId);
		$(document).find('.js-geo-current').attr('data-translit', geoCookie.geoCityTranslit);
	}
});

$(document).on('select2:select', '#geoCity', function (e) {
	let translit;

	$('.js-geo-current')
		.text(e.params.data.text)
		.attr('data-id', e.params.data.id);

	if (e.params.data.text.toUpperCase() == 'МОСКВА') {
		translit = `moscow`;
	} else if (e.params.data.text.toUpperCase() == 'САНКТ-ПЕТЕРБУРГ') {
		translit = 'saint_petersburg';
	} else {
		translit = BX.translit(e.params.data.text);
	}
	$('.js-geo-current').attr('data-translit', translit);
	$('#geoNull').click();
});

$(document).on('click', '.js-radio--geo', function () {
	let val = $(this).val(),
		text = $(this).siblings('span').text(),
		translit = $(this).attr('data-translit');
	$('#geoCity').val('').trigger('change');
	$('.js-geo-current')
		.text(text)
		.attr('data-id', val)
		.attr('data-translit', translit);
});

$(document).on('submit', '#geo-form', function (e) {
	e.preventDefault();
	let $el = $('.js-geo-current'),
		id = $el.attr('data-id'),
		name = $el.text(),
		translit;

	if (name.toUpperCase() == 'МОСКВА') {
		translit = `moscow`;
	} else if (name.toUpperCase() == 'САНКТ-ПЕТЕРБУРГ') {
		translit = 'saint_petersburg';
	} else {
		translit = BX.translit(name);
	}

	let checkIp = JSON.parse(cookie('BITRIX_SM_GEO')).geoCheckIp;
	let newCookie = {
		geoCheckIp: checkIp,
		geoCityId: id,
		geoCityName: name,
		geoCityTranslit: translit
	};
	cookie.set({BITRIX_SM_GEO: JSON.stringify(newCookie)}, {
		domain: '.' + location.host,
		path: '/',
		expires: 365
	});

	location.reload();
});

setGeoAside();

export {setGeoAside};