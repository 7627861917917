const verge = require('verge');
// const throttle = require('throttle-debounce/throttle');

const $nav = $('.js-nav'),
	$aside = $('.js-aside'),
	$navToggle = $nav.find('.nav__toggle'),
	$navLink = $nav.find('.nav__link');

function checkOpenedThirdLevel() {
	let $opened3Lvl = $('.nav__link--3.nav__link--open');

	if ($opened3Lvl.length) {
		$opened3Lvl
			.closest('.nav__list--3')
			.addClass('nav__list--open');
		$opened3Lvl
			.closest('.nav__list--3')
			.show();
		$opened3Lvl
			.closest('.nav__list--3')
			.siblings('.nav__link--parent')
			.addClass('nav__link--active');
	}
}

global.closeNav = function () {
	$('.nav__list--open')
		.hide()
		.removeClass('nav__list--open');
	$('.nav__link--active')
		.removeClass('nav__link--active');
	$('.aside').removeClass('aside--opened');
};

function toggleChild(e) {
	// if ($(e.target).hasClass('nav__text') && $(e.target).parent().hasClass('nav__link--2')) return true;
	e.preventDefault();

	let $this = $(this),
		$navListChild = $this.siblings('.nav__list');
	if ($navListChild.hasClass('nav__list--open')) {
		$navListChild
			.hide();
		$('.aside').removeClass('aside--opened');
	} else {
		$this
			.closest('.nav__list')
			.find('.nav__list--open')
			.hide()
			.removeClass('nav__list--open');
		$navListChild
			.show();
		$('.aside').addClass('aside--opened');

		if ($this.hasClass('nav__link--1')) {
			checkOpenedThirdLevel();
		}
	}
	if ($this.hasClass('nav__link--active')) {
		$this
			.removeClass('nav__link--active');
		$navListChild
			.find('.nav__link--active')
			.removeClass('nav__link--active');
		$('.aside').removeClass('aside--opened');
	} else {
		$this
			.parent()
			.siblings()
			.children('.nav__link--active')
			.removeClass('nav__link--active');
		$this
			.parent()
			.siblings()
			.find('.nav__link--active')
			.removeClass('nav__link--active');
		$this
			.addClass('nav__link--active');
		$('.aside').addClass('aside--opened');

		if ($this.hasClass('nav__link--1')) {
			checkOpenedThirdLevel();
		}
	}
	$navListChild
		.toggleClass('nav__list--open');
}

if ($nav.length) {
	checkOpenedThirdLevel();
}

$nav.on('click', '.nav__link--parent', toggleChild);

$nav.on('click', '.nav__link:not(.nav__link--2)', function (e) {
	if (!$(this).hasClass('nav__link--parent')) {
		closeNav();
	}
});

$(document).on('click', function (e) {
	if (!$nav.is(e.target) && $nav.has(e.target).length === 0 && !$aside.is(e.target) && $aside.has(e.target).length === 0) {
		closeNav();
	}
});
