import Noty from 'noty';

Noty.overrideDefaults({
	layout: 'topRight',
	theme: 'relax',
	type: 'alert',
	text: '',
	dismissQueue: true,
	template: '<div class="noty_message"><span class="noty_text"></span><div class="noty_close"></div></div>',
	animation: {
		// open: {opacity: 'show'},
		// close: {opacity: 'hide'},
		easing: 'swing',
		speed: 500
	},
	timeout: 5000,
	force: false,
	modal: false,
	maxVisible: 10,
	killer: false,
	closeWith: ['click'],
	callback: {
		onShow: function () {},
		afterShow: function () {},
		onClose: function () {},
		afterClose: function () {},
		onCloseClick: function () {},
	},
	buttons: false
})

global.showNotyfications = function (messageN, optionsN) {
	let notyOpt = {};
	if (typeof optionsN !== 'undefined') {
		notyOpt = Object.assign(optionsN);
		if (optionsN.type == undefined) {
			notyOpt.type = 'alert';
		}
		if (optionsN.layout == undefined) {
			notyOpt.layout = 'topRight';
		}
	}
	notyOpt.text = messageN;
	new Noty(notyOpt);
};

$('.js-noty').on('click', function (e) {
	e.preventDefault();
	let $this = $(this);

	new Noty({
		text: $this.data('message'),
		type: $this.data('type'),
		layout: $this.data('layout'),
		buttons: ($this.data('type') != 'confirm') ? false : [
			{
				addClass: 'btn btn--primary', text: $this.data('message-btn-ok'), onClick: function ($noty) {
					$noty.close();
				}
			},
			{
				addClass: 'btn btn--danger', text: $this.data('message-btn-cancel'), onClick: function ($noty) {
					$noty.close();
				}
			}
		]
	});
	return false;
});
